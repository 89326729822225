// Body
//
// Settings for the `<body>` element.
$gray-dark:                 #373a3c;
$gray:                      #0057a3;
// $gray:                      #55595c;
$grey-semi:                 #ccc;
$gray-light:                #515151;
$gray-lighter:              #eceeef;
$gray-lightest:             #f7f7f9;
$gray-border:               #dee2e6;

$brand-primary:             #0066cc;
$brand-success:             #5cb85c;
$brand-info:                #0057a3;
$brand-warning:             #be9030;
$brand-danger:              #d9534f;
$brand-inverse:             #000;

$primary-color: #515151;
$danger-color: #373a3c;

$theme-colors: (
"primary": #515151,
"danger": #373a3c
);

$font-size-20: 1.25rem;
$font-size-16: 1rem;
$font-size-14: .875rem;

$body-bg:    #fff;
$body-color: $gray-dark;

$top-bg: #000;
$top-color: #fefefe;


// Links
//
// Style anchor elements.

$link-color:            $brand-primary;
$link-decoration:       none;
$link-hover-color:      lighten($link-color, 15%);
$link-hover-decoration: underline;


@mixin dark-container {
    color: $body-bg;
    background: $top-bg;
    h1, h2, h3 {
        color: $white;
    }
    a {
        color: $body-bg;
    }
}

@mixin light-container {
    color: $top-bg;
    background: $body-bg;
    h1, h2, h3 {
        color: $brand-primary;
    }
    a {
        color: $top-bg;
    }
}