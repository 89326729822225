.main-nav {
    .navbar {
        padding-bottom: 0;
        z-index: 999;
    }
    .navbar-dark {
        .navbar-toggler {
            margin-bottom: 1rem;
        }
    }
    nav {
        #navbarSupportedContent {
            justify-content: center;
        }
        .nav-item {
            text-transform: uppercase;
            border-bottom: 6px solid $brand-inverse;
            color: $top-color;
            &.active {
                border-color: theme-color("primary");
                color: $gray-lightest;
            }
            & + .nav-item {
                margin-left: 3.3rem;
            }
        }
        .dropdown-menu {
            left: auto;
            background: $primary-color;
            padding: 0.5rem;
            text-transform: none;
            .dropdown-item {
                color: $body-bg;
                &:hover,
                    &:active {
                    background: theme-color("danger");
                    color: darken($white, 20%);
                }
            }
        }
    }
}
