.calendar {
    margin-top: $spacer;
    .calendar-title {
        font-size: 1.25rem;
        padding: $spacer/2 2px 0;
        text-align: center;
    }
    .calendar-body {
        .c-row {
            display: flex;
            &.title-row {
                margin-bottom: $spacer/2;
            }
        }
        .title-row {
            .btn {
                font-weight: 700;
            }
        }
        .btn {
            width: calc(14.2857% - 4px);
            margin: 2px;
            color: $gray;
        }
        .month-prev,
        .month-next {
            color: $grey-semi;
        }
        .month-actual {
            &.term {
                font-weight: 700;
                color: $black;
            }
        }
        .button-row {
            display: flex;
            justify-content: space-between;
            .btn-primary {
                color: $gray-lightest;
            }
            .btn {
                white-space: nowrap;
                width: auto;
                margin-left: $spacer;
                margin-right: $spacer;
            }
        }
    }
}