$brand-primary:             #0066cc;
$brand-success:             #5cb85c;
$brand-info:                #0057a3;
$brand-warning:             #be9030;
$brand-danger:              #d9534f;
$brand-inverse:             #000;

.kursy.admin {
    .daty {
        .btn-danger {
            background: $brand-danger;
        }
        .btn-success {
            background: $brand-success;
        }
        .btn-primary {
            background: $brand-info;
        }
        .btn-secondary {
            background: $grey-semi;
            color: $gray-dark;
        }
    }
}