.gallery {
    margin-top: $spacer * 2;
    .gallery-item {
        margin-bottom: $spacer;
        align-items: stretch;
        display: flex;
        a {
            border: 1px solid $gray-border;
            display: flex;
            align-items: stretch;
            justify-content: center;
            padding: $spacer;
            flex: 1 1 auto;

        }
    }
}