// @import url('https://fonts.googleapis.com/css?family=Dosis:300,400,600&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700|Open+Sans&subset=latin-ext');
@import "_custom";
@import "~bootstrap/scss/bootstrap";
@import "~magnific-popup/src/css/main";
@import "~summernote/dist/summernote-bs4.css";

html {
    height: 100%;
}
body {
    margin: 0;
    //    font-family: 'Dosis', sans-serif;
    //    font-family: 'Open Sans', sans-serif;
    font-family: 'Lato', sans-serif;
    background: $top-bg;
    height: 100%;
}
#particles-js {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}
a {
    color: theme-color("primary");
    &:hover {
        color: $brand-inverse;
        text-decoration: none;
    }
}
.top {
    @include dark-container;
    position: relative;
    box-shadow: 0 -0.1rem 1rem $black;
    .top-bar {
        padding-top: 30px;
        padding-bottom: 110px;
        display: flex;
        justify-content: space-between;
        .search {
            z-index: 99;
            border: 1px solid $gray-border;
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: $gray-border;
            }
            ::-moz-placeholder { /* Firefox 19+ */
                color: $gray-border;
            }
            :-ms-input-placeholder { /* IE 10+ */
                color: $gray-border;
            }
            :-moz-placeholder { /* Firefox 18- */
                color: $gray-border;
            }
            button {
                background: none;
                border: none;
                svg {
                    width: 1rem;
                    height: 1rem;
                }
            }
            input[type="text"] {
                background: none;
                border: none;
                color: $top-color;
                padding: .3rem .7rem;
            }
        }
        .logo {
            width: 288px;
            max-width: 100%;
            z-index: 991;
        }
    }
    .top-header {
        font-size: 150%;
        color: $top-color;
        line-height: 1.1;
        background-image: url(../images/belka-gorna.jpg);
        padding: .5em 0;
    }
    .jumbotron {
        margin-bottom: 0;
        background: none;
    }
    .navigation-main {
        .bg-inverse {
            background-color: transparent !important;
        }
    }
}
.top-video {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
    background: $brand-inverse;
}
.kurs {
    #program {
        h3 {
            font-size: 1.25rem;
            margin-top: $spacer;
        }
        h4 {
            font-size: 1.125rem;
            margin-top: $spacer;
            margin-bottom: 0;
        }
    }
}
main, .wrapper {
    background: $body-bg;
}
.wrapper {
    padding-bottom: 2rem;
    padding-top: 2rem;
}
footer {
    @include dark-container;
    box-shadow: 0 .1rem 1rem $black;
    padding-top: .5rem;
    padding-bottom: 0;
    a {
        &:hover {
            color: $gray;
        }
    }
    .info {
        padding-top: 3rem;
        line-height: 1.1;
        h3 {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }
        p:first-child {
            margin-top: 2.7rem;
            font-weight: 300;
        }
    }
    .webdesigner {
        p {
            text-align: right;
            font-size: 0.75rem;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            margin-top: 2rem;
            color: $gray-light;
        }
    }
    .qr-code {
        width: 80px;
        margin-top: $spacer * 2;
        margin-bottom: $spacer;
    }
}
.social {
    background: $white;
    text-align: right;
    position: fixed;
    top: 300px;
    right: 0;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-right: none;
    a {
        display: block;
        svg {
            width: 1.5rem;
            margin: $spacer/2;
            transition: width .5s ease-out;
        }
    }
    &:hover {
        a {
            svg {
                width: 3rem;
            }
        }
    }
}

.main-default {
    margin-top: 50px;
    h2 {
        font-size: 150%;
        color: $brand-primary;
        text-align: right;
        &:first-child {
            margin-bottom: 2em;
        }
    }
    h3 {
        font-size: 125%;
        font-weight: 700;
    }
    p {
        & + h2 {
            margin-top: 3em;
        }
    }
    img {
        max-width: 100%;
        height: auto;
    }
    aside {

    }
    .content-brand-list {
        list-style: none;
        display: flex;
        li {
            text-align: center;
            width: 25%;
            .fa {
                font-size: 300%;
                color: $brand-primary;
            }
        }
    }
}

.list-group {
    .active {
        a {
            color: inherit;
        }
    }
}

#mc_embed_signup_scroll {
    .email.form-control {
        border: none;
    }
    button[type=submit] {
        img {
            height: 1rem;
            width: auto;
        }
    }
}
.main-title {
    padding-top: 2rem;
    padding-bottom: 1rem;
}
.main-description {
    padding-bottom: 2rem;
}
.main-bottom {
    .courses-link {
        text-align: center;
    }
    .more-link {
        $cont-margin: 20px;
        text-align: center;
        font-size: 150%;
        color: $brand-primary;
        margin-top: -0.5em;
        &:before, &:after  {
            font-size: 200%;
            display: inline-block;
            transform: translateY(10px);
            margin-top: -20px;
        }
        &:before  {
            content: "{";
            margin-right: $cont-margin;
        }
        &:after {
            content: "}";
            margin-left: $cont-margin;
        }
    }
}
.table {
    .daty {
        span {
            display: inline-block;
            background: #da9595;
            color: #fff;
            padding: .25rem 1rem;
            margin-right: .5rem;
            margin-bottom: .5rem;
            &.actual {
                background: #006600;
            }
        }
    }
}
.card-img {
    padding: $spacer / 2;
}
.popover-body {
    ul {
        padding-left: 1.5rem;
    }
}

@import "blocks/autonav/main_nav";
@import "blocks/home_panels";
@import "blocks/main_lista";
@import "blocks/main_item";
@import "blocks/docs";
@import "blocks/form";
@import "blocks/galleries";
@import "blocks/calendar";
@import "blocks/admin";


