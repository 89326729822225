.home-panels {
    border-left: solid .5px $grey-semi;
    border-top: solid .5px $grey-semi;
    .home-panel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-top: 2rem;
        padding-bottom: 2rem;
        border: solid 1px $grey-semi;
        border-top: none;
        border-left: none;
        p {
            font-weight: 300;
            line-height: 1.1;
            text-align: center;
            padding-top: .5rem;
            color: $gray;
        }
        a {
            color: $gray;
            &:hover {
                color: lighten($gray, 20%);
                text-decoration: none;
            }
        }
    }
}
.home-cards {
    padding-top: 1rem;
    h2 {
        font-size: $font-size-20;
    }
}
.home-cards-container {
    padding-left: 0;
    .home-card {
        a {
            color: inherit;
        }
        display: flex;
        line-height: 1.1;
        margin-bottom: $grid-gutter-width / 2;
        margin-top: $grid-gutter-width / 2;
        .home-card-inner {
            padding: $grid-gutter-width / 2;
            border: $grey-semi solid 1px;
            border-radius: 6px;
            display: flex;
            flex-wrap: wrap;
            .home-card-img {
                width: 100%;
                height: auto;
                @include media-breakpoint-up(md) {
                    width: 25%;
                }
            }
            .home-card-body {
                display: flex;
                flex-direction: column;
                > div  {
                    margin-bottom: .5rem;
                    margin-top: .5rem;
                }
                @include media-breakpoint-up(md) {
                    width: 65%;
                    padding-left: $grid-gutter-width;
                }
            }
        }
        .home-card-title {
            font-size: $font-size-20;
            margin-top: 0;
        }
        .home-card-lector {
            font-size: $font-size-14;
        }
        .home-card-btn {
            color: $white;
            background: $gray;
            text-align: center;
            padding: .5rem;
            margin-bottom: .5rem;
            a {
                display: block;
            }
        }
        .home-card-date {
            margin-top: auto !important;
        }
        .home-card-price {
            font-size: $font-size-16;
        }
        .home-card-btn-show {
            font-weight: 600;
        }
        .home-card-btn-register {
            font-weight: 600;
        }
    }
}
