.doc {
    padding-bottom: 2rem;
    ul {
        list-style: none;
    }
    .regulations {
        line-height: 1.4;
        counter-reset: chapter;
        text-align: justify;
        & > li {
            text-align: left;
            margin: 20px 0 5px;
            text-align: justify;
            &:before {
                content: "\00A7 " counter(chapter) ". ";
                counter-increment: chapter;
                font-weight: bold;
                text-align: center;
                display: block;
            }
        }
    }
}