.main-lista {
    width: 100%;
    .media {
        border: $grey-semi solid 1px;
        padding: $spacer;
        margin-bottom: $spacer / 2;
        border-radius: 5px;
        h5 {
            line-height: 1.1;
        }
        p {
            a {
                color: $gray-dark;
                font-weight: 500;
            }
        }
        img {
            max-width: 120px;
            height: auto;
            margin-top: .2rem;
        }
        .daty {
            margin-bottom: 0;
            span {
                display: inline-block;
                background: $gray;
                color: $body-bg;
                padding:  $spacer / 4 $spacer;
                margin-right: $spacer / 2;
                margin-bottom: $spacer / 2;
            }
        }
        .linki {
            padding-left: 0;
            padding-right: 0;
            .home-card-btn {
                a {
                    display: block;
                    color: $white;
                    background: $gray;
                    text-align: center;
                    padding: .5rem;
                    margin-bottom: .5rem;
                }
            }
        }
    }
}