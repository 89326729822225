textarea.form-control {
    height: 8rem;
}


.pad {
    border-bottom: 1px solid $grey-semi;
    padding-bottom: $spacer;
    .form-check-label {
        span {
            display: block;
        }
    }
    legend {
        font-weight: 600;
    }
}
.payment {
    margin-bottom: 1rem;
}
#paypal-button-container.disabled {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        opacity: .4;
        z-index: 100;
    }
}