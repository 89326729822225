.main-item {
    width: 100%;
    margin-top: $spacer * 2;
    .nav-item {
        .nav-link {
            color: $brand-primary;
        }
    }
    .tab-content {
        padding: 1rem;
        border: 1px solid $gray-border;
        border-top: none;
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem;
    }
    .list-dates {
        margin-top: $spacer;
        margin-bottom: $spacer;
        p {
            margin-bottom: $spacer / 2;
        }
        .termin-data {
            font-weight: 500;
            color: $top-bg;
        }
        .termin-adres {
            line-height: 1.1;
        }
    }
    .list-wykladowcy {
        .media.list-group-item {
            display: flex;
            align-items: center;
            img {
                max-width: 80px;
            }
        }
    }
}